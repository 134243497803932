<template>
  <div class="search">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="onLoad"
    >
      <!-- <van-sticky>
        <search-top @changeOrder="changeOrder" :order="order"></search-top>
      </van-sticky> -->
      <product-list :productList="productList" :isPlaceholder="isPlaceholder" ref="search"></product-list>
    </van-list>
  </div>
</template>
<script>
import ProductList from '@components/common/ProductList'

import Vue from 'vue'
import { Sticky, List } from 'vant'

Vue.use(List).use(Sticky)
export default {
  name: 'SearchClass',
  components: {
    ProductList,
    // SearchTop
  },
  data () {
    return {
      productList: [],
      className: '', // 搜索关键词
      pageIndex: 1,
      order: '', // 排序
      // isNoData: false,
      // isfirst: true,
      loading: true,
      finished: false,
      isPlaceholder: false,
      classId: null// 分类id或分类id列表
    }
  },
  computed: {
    finishedText () {
      if (this.isPlaceholder) {
        return ''
      } else {
        return this.$t.noMoreData
      }
    }
  },
  mounted () {
    const { ClassName, Order, title, ClassId } = this.$route.query
    this.className = ClassName
    this.order = Order
    this.getSearchProductByPage()
    console.log(this.$route.query)
    console.log(title)
    // console.log(this.ClassName)
    this.className && (document.title = this.className)
    ClassId && (this.classId = ClassId)// 兼容全部分类
  },
  methods: {
    // 获取搜索页数据
    getSearchProductByPage () {
      this.$commonMethod.showLoading()
      let param = {}
      if (this.order !== 1 && this.order !== 2) {
        param = {
          ClassName: this.className,
          PageIndex: this.pageIndex
        }
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            PageIndex: this.pageIndex
          }
        }
      } else {
        param = {
          ClassName: this.className,
          Order: this.order,
          PageIndex: this.pageIndex
        }
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            PageIndex: this.pageIndex
          }
        }
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then(res => {
          console.log(res)
          const data = res.data
          this.productList = data
          // this.isfirst = false;
          if (
            this.productList === null ||
            this.productList === '' ||
            this.productList.length === 0
          ) {
            this.isPlaceholder = true
          } else {
            this.isPlaceholder = false
          }
          this.loading = false
          console.log(this.isPlaceholder)
          this.$commonMethod.hideLoading()
        })
        .catch(error => {
          this.isfirst = false
          // console.log(error);
          this.$commonMethod.hideLoading()
          this.$commonEnv.commonAction('showDialog', {
            msg: error.message,
            status: error.status
          })
        })
      // console.log("这是isfirst" + this.isfirst);
    },
    // 筛选
    changeOrder (type) {
      this.$refs.search.scrollTop = 0
      console.log(this.$refs.search.scrollTop)
      this.order = type
      // if (type === !this.order) return;
      this.pageIndex = 1
      // this.isNoData = false;
      this.loading = true
      this.finished = false
      this.getSearchProductByPage()
    },
    // 加载更多
    onLoad () {
      console.log('执行加载更多')
      let param = {}
      if (this.order !== 1 && this.order !== 2) {
        param = {
          ClassName: this.className,
          PageIndex: this.pageIndex + 1
        }
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            PageIndex: this.pageIndex + 1
          }
        }
      } else {
        param = {
          ClassName: this.className,
          Order: this.order,
          PageIndex: this.pageIndex + 1
        }
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            Order: this.order,
            PageIndex: this.pageIndex + 1
          }
        }
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then(res => {
          // console.log(res.data);
          const data = res.data
          console.log(data)
          if (data.length === 0) {
            this.loading = false
            this.finished = true
            // this.isNoData = true;
          } else {
            this.loading = false
            this.productList = this.productList.concat(data)
            this.pageIndex = this.pageIndex + 1
          }
        })
        .catch(error => {
          // console.log(error);
          this.$commonEnv.commonAction('showDialog', {
            msg: error.message
          })
        })
      // console.log("这是isfirst" + this.isfirst);
    }
  }
}
</script>
<style lang="scss" scoped>
.search {
  width: 100%;
  background-color: #f6f6f6;
  ::v-deep .van-list__finished-text {
    font-size: 14px;
    padding: 14px 0;
  }
  ::v-deep .van-loading__text {
    font-size: 14px;
    padding: 5px 0;
  }
}
</style>
